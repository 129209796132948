.unauthorized {
    text-align: center;
    margin-top: 20vh;
    padding: 20px;
}
.unauthorized .login__logo-container {
    margin-bottom: 3rem;
}
.unauthorized button {
    padding: 0.5rem 1rem;
    border: 1px solid gray;
    margin-top: 1rem;
}