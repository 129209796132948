@media (min-width: 992px) {

    .nowrap {
        white-space: nowrap; 
    }
}
.form-group {
    margin-bottom: 20px;
}

@media (min-width: 767px) {

    .create_module {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .create_module .form-group {
        flex-grow: 1;
        margin-right: 20px;
        width: 40%;
    }
    .create_module .form-group:nth-child(even) {
        margin-right: 0;
    }
    .create_module .form-button {
        align-items: flex-end;
        display: flex;
    }
}

@media (min-width: 992px) {

    .create_module {
        flex-wrap: nowrap;
    }
    .create_module .form-group {
        flex-grow: 1;
        margin-right: 20px;
        width: auto;
    }
    .create_module .form-group:nth-child(even) {
        margin-right: 20px;
    }
    .create_module .form-group:nth-last-child(-n+1) {
        margin-right: 0;
    }
}
.create_module .btn {
    background-color: #7B8898;
    width: 100%;
}


/************** LIST-PAGES ******************/

@media (min-width: 992px) {
    .container .header--fixed {
        width: calc(100% - 300px);     
        height: 200px;
        position: fixed; 
        background-color: #FFF;
        padding: 40px 40px 0;
    }
    .container .body--scroll {
        padding-top: 200px;
    }

    .container {
        max-width: 100%;
    }
}

.header--infopage {
    margin-bottom: 40px;
}

.header--fixed {
    padding: 20px 20px 0;
}


/************ Multi toggle to right in top *************/

.events_schedulesync .row {
    align-items: flex-end;
}
.events_schedulesync .search-form {
    margin-bottom: 0;
}
.events_schedulesync .toggle p {
    margin-bottom: 0;
}
.events_schedulesync .toggle:first-child {
    margin-bottom: 1rem;
}


/****************** SEARCH ***************/
.search-form {
    display: inline-flex;
    width: 100%;
    margin-bottom: 20px;
}


/************ Multi filter ScheduleSync-Users *************/

    .users_schedulesync .search-box select {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
    }

.users_schedulesync .search-box .button-box {
    display: flex;
    justify-content: flex-end;
}
    .users_schedulesync .search-box .button-box .btn {
        margin-left: 1rem;
    }

    .users_schedulesync .search-box select {
        margin-bottom: 20px;
    }



    /****************** SEARCH ***************/
    .search-form {
        display: inline-flex;
        width: 100%;
        margin-bottom: 20px;
    }
.search-form input.form-control:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
}
.search-form .btn {
    width: 150px;
    margin-left: 20px;
    background-color: lightgray;
    color: black;
    border: none;
}
@media (min-width: 992px) {
    .search-form {
        margin-bottom: 40px;
    }
}

/************ TABLE **************/
.table td {
    padding: 0.4rem 0.75rem;
}
.table tbody th, .table td {
    border-bottom: 1px solid #dee2e6;
    border-top: none;
}
.table th {
    padding: 0.5rem 0.75rem;
}
.overwiew-table tbody tr.list_table:hover {
    background-color: #eaeaea;
}
.overwiew-table tbody tr.list_table.expanded {
    background-color: #f5f5f5;
}
.overwiew-table tbody tr.list_table.expandable {
    cursor: pointer;
}
.user .overwiew-table tbody tr:nth-child(odd) {
    background-color: #f5f5f5;
}

/****** MINI TABLE **********/
.table.mini {
    border: none;
}

.table.mini thead tr {
    background-color: inherit;
    color: inherit;
    font-size: 13px;
    font-weight: 600;
    border-bottom: 2px solid #ddd;
}

.table.mini tbody .list_table:last-child {
    border-bottom: none;
}

.table.mini tbody .list_table:last-child td {
    border-bottom: none;
}

.instancelogs .overwiew-table tbody {
    font-size: 14px;
}

.table thead tr {
    background: #7B8898;
    color: #fff;
    cursor: default;
}
.table thead th {
    border-bottom: none;
}
.table > thead > tr.filter_header {
    background-color: #fff;
    border-bottom: 1px solid #dee2e6;
}
.filter_header th {
    padding: 0.4rem 0.75rem;
}
.filter_header select {
    border-radius: 5px;
    padding: 0.3rem;
    border-color: #ccc;
}

.auditlog .filter_header select {
    font-size: 14px;
}
.mobile-header-table, .header-table {
    background: #7b8898;
    padding: 0.5rem 0.75rem;
    color: #fff;
    font-weight: 600;
}
@media (max-width: 700px) {
    .table .list_table .mobile-hide {
        display: none;
    }
}
@media (min-width: 701px) {
    .mobile-header-table {
        display: none;
    }
}
.joined-list a:not(:last-child):after {
    content: ', ';
}


/************ SINGLE PAGE TABLE ************/

.info_table tbody tr {
    border-top: 1px solid #eaeaea;
}
.info_table tbody tr:nth-child(odd) {
    background-color: #f5f5f5;
}

.info_table > tbody > .inactive {
    font-style: italic;
}
.info_table > tbody > tr > th {
    padding: 0.4rem 0.75rem;
}

    .td-padding {
        padding: 0.4rem 0.75rem;
    }
.members_count {
    font-size: 13px;
    padding-left: 1rem;
}

.schedulesync .header_member_table {
    display: flex;
    justify-content: space-between;
}
.input_week {
    width: 200px;
}
.schedulesync .header_member_table.large {
    display: flex;
    align-items: center;
}
.schedulesync .header_member_table.large p {
    margin: 0;
}
.header_member_table {
    background: #7b8898;
    padding: 0.5rem 0.75rem;
    color: #fff;
    font-weight: 600;
}
.header_member_table.large .select-input {
    height: inherit;
    padding: 0.25rem 0.75rem;
}

.header_member_table.select-status .mb-3 {
    margin-bottom: 0 !important;
}
    .header_member_table.select-status  {
        padding: 0.6rem 0.75rem;
    }

@media (max-width: 700px) {
    .header_member_table.large {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.right {
    float: right;
    padding: 0 0.5rem;
}
@media (min-width: 701px) {
    .ml-right {
        float: right;
    }
}
.right:hover {
    cursor: pointer;
}
.single .col-sm-6, .single .col-sm-8, .single .col-sm-4 {
    max-width: 100%;
    flex: 0 0 100%;
}

.header_member_table.filter-under-text {
    display: flex;
    flex-direction: column; 
}
    .header_member_table.filter-under-text .input_week {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
.header_member_table.filter-under-text .right {
    align-self: flex-end;
}

    @media (min-width: 992px) {
        .header_member_table {
            margin-bottom: 0;
        }
            .header_member_table.filter-under-text {
                flex-direction: row;
                justify-content: space-between;
            }
                .header_member_table.filter-under-text .input_week {
                    width: 200px;
                    margin-top: 0;
                    margin-bottom: 0;
                }
                .header_member_table.filter-under-text .right {
                    align-self: unset;
                }
    }

    @media (min-width: 1200px) {
        .single .col-sm-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }

        .single .col-sm-4 {
            flex: 0 0 33,33%;
            max-width: calc(100% / 3);
        }

        .single .col-sm-8 {
            flex: 0 0 66,66%;
            max-width: calc(100% / 3 * 2);
        }
    }

    @media (max-width: 600px) {
        .info_table > tbody th {
            display: none;
        }

        .info_table thead tr {
            background: #7B8898;
            line-height: 1.7;
        }

        .info_table thead th {
            border: none;
        }

        .info_table tbody tr {
            border-top: none;
            line-height: 1.5;
        }
    }
    /********** DATA-LABEL **************/

    @media (max-width: 700px) {
        .table {
            width: 100%;
        }

        .overwiew-table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            color: blue;
        }

        .table tr {
            border-bottom: 1px solid #ddd;
            display: block;
            line-height: 1;
        }

        .overwiew-table tr {
            padding: 10px 0;
        }

        .table tbody tr:nth-child(odd) {
            background: #f5f5f5;
        }

        .table td {
            border: none;
            display: block;
            text-align: right;
            min-height: 25px;
        }

            .table td::before {
                content: attr(data-label);
                float: left;
                font-weight: 600;
                padding-right: 10px;
            }

        .table .mobile-title {
            font-size: 16px;
        }
    }

    .table p {
    margin: 20px 0 10px;
    padding-left: 5px;
    font-weight: 600;
}

.info_right_side {
    background: #f4f4f4;
    padding-top: 20px;
    border-radius: 1px;
}
.info_right_side ul {
    padding-left: 10px;
}
.info_right_side li {
    list-style: none;
}

th {
    font-weight: 600;
}
.list_table {
    display: table-row;
    color: inherit;
    border-bottom: 3px solid #ddd;
}
a:hover {
    text-decoration: none;
    color: inherit;
}
.listinfo_h5 {
    margin-bottom: 20px;
}
.address_h6 {
    padding-left: 5px;
    text-transform:uppercase;
}
.userinfo_th {
    width: 150px;
}
.list_members .studentgroup_list a {
    margin-left: 10px;
}
.table td.studentgroup_list {
    padding-left: 1rem;
}


@media (min-width: 600px) {
    .list_table {
        border-bottom: 1px solid #ddd;
    }
}



/*************** TRACKINGS *********************/
.tracking {
    margin-top: 40px;
}
@media (max-width: 600px) {
    .tracking h5 {
        background: #7b8898;
        padding: 0.5rem 0.75rem;
        color: #fff;
        font-weight: 600;
        margin-bottom: 0;
    }
}


/************* EDIT-MODULE ************/
.edit_module {
    margin-top: 1rem;
}
.documentation-icon {
    cursor: pointer;
    margin-left: 1rem;
    color: #fff;
    font-size: 25px;
}
.documentation-icon.small {
    font-size: 18px;
}
.documentation-icon.fs-color {
    color: #7b8898;
}
.card-header {
    background-color: #7b8898;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.card-header .btn {
    border-color: #fff;
    color: #fff;
    height: 35px;
    padding: 0;
}

/************* BASE-MODULE ************/
.base_module .form-check, .active-checkbox {
    margin-bottom: 20px;
}
input:disabled, .btn:disabled, select:disabled {
    cursor: not-allowed;
}

/************* IMPORTSCHOOLUNIT MODULE ************/
.schoolunit_list {
    column-count: 1;
    list-style: none;
    padding-left: 0;
}

@media (min-width: 600px) {
    .schoolunit_list {
        column-count: 2;
    }
}
@media (min-width: 992px) {
    .schoolunit_list {
        column-count: 3;
    }
}
@media (min-width: 1200px) {
    .schoolunit_list {
        column-count: 4;
    }
}

/**************** TIETOEDUCATION ****************/
@media (min-width: 992px) {
    .govern_schools {
        display: grid;
        grid-template-columns: 30% auto;
    }
}
@media (min-width: 1200px) {
    .govern_schools {
        display: grid;
        grid-template-columns: 20% auto;
    }
}


/*************** IMPORTMODULE *****************/
.import-module-info-box {
    margin-bottom: 10px;
}
.import-module-info-box button {
    background-color: #7b8898;
}
@media (min-width: 600px) {
    .import-module-info {
        display: flex;
    }
    .import-module-info-box {
        width: 50%;
    }
    .import-module-info-box button {
        float: right;
    }
}

.checkbox-list-items {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
}

.form-check-input {
    vertical-align: text-top;
    margin-right: 0.5rem;
    float: none;
}
.form-check-label {
    display: inline-flex;
    align-items: center;
}

.invalid-feedback {
    white-space: normal;
}


.active_flag {
    white-space: nowrap;
}
.active_flag:before {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #23807d;
    margin-left: 5px;
    content: '';
}
.active_flag:after {
    content: 'Aktiv';
    font-size: 10px;
    vertical-align: super;
    margin-left: 2px;
}

.role_row.inactive {
    font-style: italic;
}


/* UserActivity */


/*.user-activity .user_row div:before {
    content: attr(title);
    font-weight: 600;
    padding-right: 10px;
    float: left;
}
.user-activity .user_row {
    border-bottom: 1px solid #dee2e6;
    padding: 0.5rem 0.75rem;
    text-align: right;
}
.user-activity .user_row:nth-child(odd) {
    background-color: #f5f5f5;
}
.user-activity .user_row.header {
    display: none;
}*/

/*
@media (min-width: 600px) {

    .user-activity .user_row {
        display: flex;

        text-align: left;
    }
    .user-activity .user_row > div {
        width: 25%;
    }


    .user-activity .user_row div:before {
        display: none;
    }
    .user-activity .user_row.header {
        display: flex;
        font-weight: 600;
    }

}*/



/******** AuditLog *********/

.auditlog .list_table {
    font-size: 14px;
}


/******** InstanceLogs *********/

.instancelogs .paragraph td {
    border: none;
    padding: 1rem 0.75rem;
}
.instancelogs .paragraph:hover {
    background: none;
}
.flex-row-right {
    text-align: right;
}

.table td.td-download {
    padding: 0.2em 0.75em;
}
  
    .table td.download-td {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }

.mobile_table_header {
    display: none;
}

@media (max-width: 700px) {
    .event_member td {
        display: inline-flex;
    }
    .event_member td:first-child {
        min-width: 80%;
    }
    .event_member td:last-child {
        text-align: right;
    }
    .mobile_table_header {
        display: block;
        background: #7b8898;
        padding: 0.5rem 0.75rem;
        color: #fff;
        font-weight: 600;
        margin: 1px 0;
    }
}

/** select **/
.select-input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.header-menu {
    background: #7b8898;
    color: #fff;
    padding: 0.5rem 0.75rem;
    font-size: 14px;
    margin-bottom: 0;
}
@media (min-width: 701px) {
    .header-menu {
        display: none;
    }
}

.multiple-cards .card {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
    .multiple-cards .card:first-child {
        margin-top: 0;
    }
    .multiple-cards .card:last-child {
        margin-bottom: 0;
    }


@media (min-width: 1200px) {
    .double-col {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 1rem;
    }
    .double-col > div {
        width: 48%;
    }
}


/******   DNP   *******/

.dnp-table .head {
    background: #7B8898;
    color: #FFF;
    font-weight: 600;
}
.dnp-table .row > div {
    padding: 0.5rem 0.75rem;
}
.dnp-table .body .remove-formgroup-css .mb-3 {
    margin-bottom: 0 !important;
}
.dnp-table .body .row {
    border-bottom: 1px solid #ccc;
}
.dnp-table .row {
    margin-right: 0;
    margin-left: 0;
}

@media (min-width: 700px) {
    .dnp-table .body .row > div:first-child {
        display: flex;
        align-items: center;
    }
    .dnp-table .col-50 > div {
        width: 50%;
    }
}