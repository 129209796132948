

.filter-bottom {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0 0 10px #ccc;  
    padding: 9px 20px;
    width: 100%;
}

.filter-bottom .btn {
    float: right;
}

.vertical-center {
    margin: auto;
}

@media (max-width: 577px) {
    .filter-bottom .row .col-sm-3 {
        width: 50%;
    }
    .filter-bottom .row .col-sm-6 {
        order: 3;
    }
}

@media (min-width: 992px) {
        .filter-bottom {
        width: calc(100% - 300px);
        padding: 9px 40px;
    }
}
