/*FONT*/

h1 {
    color: #354052;
    font-size: 1.8rem;
    margin-bottom: 2rem;
}
@media (min-width: 576px) {
    h1 {
        margin-bottom: 2rem;
    }
}
@media (min-width: 992px) {
    h1 {
        margin-bottom: 40px;
        font-size: 2.5rem;
    }
}

.large-filter h1 {
    margin-bottom: 1rem;
}

a {
    text-decoration: none;
}
.small-italic {
    font-size: 0.8rem;
    font-style: italic;
        margin-bottom: 0;
}

.flowsync-btn {
    background-color: #23807d;
    border-color: #23807d;
}
.btn-secondary {
    background-color: #7B8898;
    border-radius: 0;
}

    .flowsync-btn.disabled:hover {
        background-color: #6c757d;
        cursor: not-allowed;
    }

.btn.icon-btn, .btn.icon-btn:first-child:active {
    color: #7b8898;
    background-color: transparent;
    border: none;
    padding: 0 0 0 3em;
}

@media (min-width: 768px) {
    .btn.icon-btn, .btn.icon-btn:first-child:active {
        padding: 0 20px;
    }
}

.right p {
    margin-bottom: 0;
}
.margin-right1 .icon {
    margin-right: 10px;
}

.padding-40 {
    padding: 20px 20px 50px;
}
@media (min-width: 577px) {
    .padding-40 {
        padding-bottom: 20px;
    }
}

@media (min-width: 992px) {
    .padding-40 {
        padding: 40px;
    }
}
@media (min-width: 992px) {
    .children .container {
        margin-top: 40px;
    }
}

@media (min-width: 768px) {
    .md-w-50 {
        width: 50%;
    }
}

@media (min-width: 1200px) {
    .lg-w-25 {
        width: 25%;
    }
}

.container {
    margin: 0 auto;
    max-width: 100%;
}

.push--right {
    margin-right: 10px;
}

.push--vertical {
    margin: 10px 0;
}
.space-between {
    justify-content: space-between;
}
.children-mr-3 > * {
    margin-right: 1rem;
}



/*filter more then one row in top of table-page*/

@media (max-width: 576px) {
    .s-switch {
        flex-direction: column-reverse;
    }
}

@media (min-width:600px) {
    .large-filter h1 {
        margin-bottom: 1rem;
    }
    
    .large-filter .row .mb-3 {
        margin-bottom: 0.5rem !important;
    }
}

.table thead th.mw-1 {
    float: right;
}

.pr-40 {
    padding-right: 20px;
}

.pl-40 {
    padding-left: 20px;
}

.pb-40 {
    padding-bottom: 30px;
}

.pt-40 {
    padding-top: 20px;
}
@media (min-width: 1200px) {
    .pr-40 {
        padding-right: 40px;
    }
    .pl-40 {
        padding-left: 40px;
    }
    .pb-40 {
        padding-bottom: 40px;
    }
    .pt-40 {
        padding-top: 40px;
    }
}


/*FLEXBOX*/
@media (min-width: 576px) {
    .dir-column-ml {
        flex-direction: column;
    }
}

@media (min-width: 992px) {
    .dir-column-lg {
        flex-direction: column;
    }
}

.pointer {
    cursor: pointer;
}
.file-download-link {
    color: blue;
    cursor: pointer;
}
.file-download-link:hover {
    text-decoration: underline;
}

.disabled.form-check-label {
    color: gray;
}



button.btn-icon, button.btn-icon:hover, button.btn-icon:disabled {
    background: none;
    color: #7b8898;
    border: none;
}
.w20px {
    width: 20px;
}