.tabs {
    display: flex;
}
.tab {
    background-color: #eaeaea;
    padding: 0.5em 1em;
    font-weight: 600;
    border: 1px solid;
    border-bottom: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 5px;
    cursor: pointer;
    border-color: #ccc;
}
    .tab.active {
        background-color: #7b8898;
        color: #fff;
    }

.tab.lg {
    display: none;
}

@media (min-width: 1200px) {
    .tab.lg {
        display: block;
    }
    .tab.sm {
        display: none;
    }
}

.tab-border {
    background-color: #7b8898;
    width: 100%;
    height: 2px;
}