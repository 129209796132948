

@media (max-width: 576px) {
    .pagination-box {
        padding-top: 9px;
    }
}
.pagination-box {
    text-align: center;
}


/* Removing wierd defaults in Chrome */
.pagination-box > ul {
   margin-bottom: 0;
}
.pagination {
    display: inline-flex;
    color: #7B8898;
}
.pagination li {
    padding: 5px 10px;
    border-right: .5px solid #dee7e7;
    border-left: .5px solid #dee7e7;
    border-top: 1px solid #dee7e7;
    border-bottom: 1px solid #dee7e7;
}
.pagination > li {
    cursor: pointer;
}
.pagination > li:hover {
    background-color: #d8e1ed;
}
.pagination .active {
    color: #fff;
    cursor: default;
    background-color: #7B8898;
}
.pagination .active:hover {
    background-color: #7B8898;
}
.pagination .disabled {
    color: #dee7e7;
    cursor: default;
    background-color: inherit;
}
.pagination .disabled:hover {
    background-color: inherit;
}
.nobreak {
    white-space: nowrap;
}
.showOnLg {
    display: none;
}

.hideOnLg {
    display: block;
}
.pagination-box .hide {
    display: none;
}

@media (min-width: 992px) {
    .showOnLg {
        display: block;
    }
    .hideOnLg {
        display: none;
    }
}

@media (min-width: 992px) {
    .no-padding.pagination-box {
        padding: 20px 0 0;
    }
}


.pagination-dots {
    width: 30px;
    align-self: center;
}