
    /*Chart*/
.instance-phase-chart-container {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
}
.instance-phase-chart {
    text-align: center;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
}
.instance-phase-chart .phase-title {
    margin-top: 0.5rem;
}
.instance-phase-chart > div {
    height: 2rem;
    position: relative;
}
    .instance-phase-chart:not(:first-child) > div:before  {
        content: '';
        height: 2px;
        background: lightgray;
        position: absolute;
        top: 50%;
        z-index: -1;
        width: calc((100% / 2) - 0.5rem);
    }

    .instance-phase-chart:not(:last-child) > div:after {
        content: '';
        height: 2px;
        background: lightgray;
        position: absolute;
        top: 50%;
        left: calc(50% + 1rem);
        z-index: -1;
        width: calc((100% / 2) - 0.5rem);
    }
    
    .instance-phase-chart.animation:not(:last-child) > div:after {
        width: 0;
        animation: increase 1s;
        animation-fill-mode: forwards;
    }

    .instance-phase-chart.animation:not(:first-child) > div:before {
        width: 0;
        animation: increase 1s;
        animation-delay: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes increase {
        100% {
            width: calc((100% / 2) - 0.5rem);
        }
    }
    .instance-phase-chart > div:before {
        left: -0.5rem;
    }
    .instance-phase-chart > div:after {
        right: -0.5rem;
    }

    .instance-phase-chart .icon {
        font-size: 2rem;
        position: relative;
        display: inline-flex;
        border-radius: 50%;
        border: 2px solid white;
        background: white;
    }
    .instance-phase-chart .phase-spinner {
        display: block;
        width: 2rem;
        height: 2rem;
    }


/******* TOOLTIP ******/

.tooltip-inner {
    background: #0e303b;
    color: #fff;
    border: 2px solid #eaeaea;
    text-align: right;
    padding: 0.5rem;
    max-width: 100%;
}
    .tooltip-inner > span {
        display: block;
        white-space: nowrap;
    }
    .tooltip-inner > p {
        margin-bottom: 0.5rem;
    }
    .tooltip-inner > span .title {
        font-weight:100; 
        float: left;
        margin-right: 1rem;
    }

   

/******* PHASE TABLE ******/

.complete-phase-table > .head {
    display: none;
}
.complete-phase-table .phase-header .info, .complete-phase-table .phase-header .dates > span {
    display: block;
    text-align: right;
    padding: var(--td-padding);
}
    .complete-phase-table .phase-header .info:before, .complete-phase-table .phase-header .dates > span:before {
        content: attr(data-label);
        float: left;
        font-weight: 600;
        padding-right: 10px;
    }
    .complete-phase-table .phase-header .info .icon {
        margin-right: 1rem;
    }
        .complete-phase-table .phase-header .info .icon > div {
            display: inline;
        }
        .complete-phase-table .phase-header .info .icon .phase-spinner {
            width: 1rem;
            height: 1rem;
        }

.complete-phase-table .phase-header {
    border-bottom: 1px solid var(--border-color);
    display: block;
}
:root {
    --phase-margin-left: 20rem;
    --td-padding: 0.4rem 0.75rem;
    --border-color: #ddd;
    --table-background-color: #f5f5f5;
}

@media (max-width: 1200px) {
    .complete-phase-table .phase-row:nth-child(odd) > .phase-header {
        background: var(--table-background-color);
    }
    .complete-phase-table .phase-header .info, .complete-phase-table .phase-header .dates > span {
        line-height: 1;
    }
}

@media(min-width: 1200px) {
    /*Header*/
    .complete-phase-table > .head {
        display: block;
        background: #7B8898;
        display: flex;
        color: #fff;
        font-weight: 600;
    }
        .complete-phase-table > .head > div {
            width: calc(100% / 4);
            padding: var(--td-padding);
        }
            .complete-phase-table > .head > div:first-child {
                min-width: var(--phase-margin-left);
            }

    /*Body*/
    /*Header*/
    .complete-phase-table .phase-header:hover {
        background-color: #eaeaea;
        cursor: pointer;
    }
    .complete-phase-table .phase-header .info {
        min-width: var(--phase-margin-left);
        display: inline-block;
        font-weight: 600;
        padding: var(--td-padding);
    }
        .complete-phase-table .phase-header .dates > span {
        width: calc((100% - var(--phase-margin-left)) / 3);
        display: inline-block;
        padding: var(--td-padding);
    }
    .complete-phase-table .phase-header .info, .complete-phase-table .phase-header .dates > span {
        text-align: left;
    }
        .complete-phase-table .phase-header .info:before, .complete-phase-table .phase-header .dates > span:before {
            content: none;
        }
}

.complete-phase-table .no-result {
    padding-top: 0.4rem;
}

@media (max-width: 1199px) {

    /*Body*/
    .phase-mini-header {
        display: none;
    }
    .phase-data > span:before {
        content: attr(data-label);
        font-weight: 600;
        margin-right: 1rem;
    }
    .phase-data  {
        margin-left: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-bottom: 1px solid var(--border-color);
        border-top: 1px solid var(--border-color);
    }
    .phase-data > span {
        display: flex;
        justify-content: space-between;
        padding: 0 0.5rem;
        border-bottom: 1px solid var(--border-color);
    }
    .phase-data > span:nth-child(4n+1), .phase-data > span:nth-child(4n+2) {
        background: var(--table-background-color);
    }
}

@media (min-width: 1200px) {
    .phase-data, .phase-mini-header {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        padding-left: 2.1rem;
    }
    .phase-mini-header {
        font-weight: 600;
        font-size: 13px;
    }
    .phase-data:nth-child(odd) {
        background: var(--table-background-color);
    }
    .show-phase-data .phase-header {
        background-color: var(--table-background-color);
    }
    .show-phase-data .phase-mini-header {
        border-bottom: 2px solid var(--border-color);
    }
    .show-phase-data .phase-data {
        border-bottom: 1px solid var(--border-color);
    }
    .show-phase-data .phase-data > span, .show-phase-data .phase-mini-header > span {
        padding: var(--td-padding);
    }

    .complete-phase-table .no-result {
        padding: var(--td-padding);
    }
}
