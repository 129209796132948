/*********
    Text
*********/

.text-right {
    text-align: right;
}

/*********
    margins
*********/

.push--top {
    margin-top: 1rem;
}

.push--left {
    margin-left: 1rem;
}

.push--right {
    margin-right: 1rem;
}

.push--bottom {
    margin-bottom: 1rem;
}

.push--sides {
    margin-right: 1rem;
    margin-left: 1rem;
}

/********
 Cursor
*********/

.c-pointer {
    cursor: pointer;
}