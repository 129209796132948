.fa-button {
    cursor: pointer;
}

.fa-button:hover {
    color: dimgray;
}

.fa-button-disabled {
    cursor: not-allowed;
    color: #6c757d
}
