
.toggle {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.single .toggle {
    justify-content: flex-start;
}
@media (min-width: 1200px) {
    .single .toggle {
        justify-content: flex-end;
    }
}

.toggle-switch {
    position: relative;
    min-width: 60px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
    margin-left: 10px;
    height: 25px;
}

.toggle-switch-checkbox {
    display: none;
}

.toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #ccc;
    border-radius: 20px;
    margin: 0;
}

.toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

    .toggle-switch-inner::before, .toggle-switch-inner::after {
        display: block;
        float: left;
        width: 50%;
        height: 25px;
        padding: 0;
        line-height: 25px;
        font-size: 14px;
        color: white;
        font-weight: 600;
        box-sizing: border-box;
    }

    .toggle-switch-inner:before {
        content: attr(data-yes);
        text-transform: uppercase;
        padding-left: 10px;
        background-color: #7b8898;
        color: #fff;
    }
    .large .right .toggle-switch-inner:before {
        background-color: #23807d;
    }

.toggle-switch-disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

    .toggle-switch-disabled::before {
        background-color: #ccc;
        cursor: not-allowed;
    }

.toggle-switch-inner::after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
}

.toggle-switch-switch {
    display: block;
    width: 20px;
    margin: 3px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    border: 0 solid #ccc;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 0px;
}
