.turbo-wrapper {
    margin: 10px 0
}

.turbo2000 {
    animation: turbo 2000ms infinite;
}

.turbo1900 {
    animation: turbo 1900ms infinite;
}

.turbo1800 {
    animation: turbo 1800ms infinite;
}

.turbo1700 {
    animation: turbo 1700ms infinite;
}

.turbo1600 {
    animation: turbo 1600ms infinite;
}

.turbo1500 {
    animation: turbo 1500ms infinite;
}

.turbo1400 {
    animation: turbo 1400ms infinite;
}

.turbo1300 {
    animation: turbo 1300ms infinite;
}

.turbo1200 {
    animation: turbo 1200ms infinite;
}

.turbo1100 {
    animation: turbo 1100ms infinite;
}

.turbo1000 {
    animation: turbo 1000ms infinite;
}

.turbo900 {
    animation: turbo 900ms infinite;
}

.turbo800 {
    animation: turbo 800ms infinite;
}

.turbo700 {
    animation: turbo 700ms infinite;
}

.turbo600 {
    animation: turbo 600ms infinite;
}

.turbo500 {
    animation: turbo 500ms infinite;
}

.turbo400 {
    animation: turbo 400ms infinite;
}

.turbo300 {
    animation: turbo 300ms infinite;
}

.turbo200 {
    animation: turbo 200ms infinite;
}

.turbo100 {
    animation: turbo 200ms infinite;
}

@keyframes turbo {
    0% {
        background-color: #F99;
    }

    50% {
        background-color: #FDD;
    }

    100% {
        background-color: #F99;
    }
}
