.module-trigger-overview {
    background-color: #ececec;
    height: 100vh;
}

@media (min-width: 992px) {
    .module-trigger-overview {
        padding-top: 40px;
    }
}

.react-flow__node.module-trigger-overview__node,
.react-flow__node.module-trigger-overview__node--cron,
.react-flow__node.module-trigger-overview__node--import,
.react-flow__node.module-trigger-overview__node--export,
.react-flow__node.module-trigger-overview__node--inactive {
    width: 150px;
    height: 60px;
    display: grid;
    align-content: center;
}

.react-flow__node.module-trigger-overview__node--cron {
    background-color: #0e303b;
    color: #fafafa;
}

.react-flow__node.module-trigger-overview__node--import {
    background-color: #f5f5dc;
}

.react-flow__node.module-trigger-overview__node--export {
    background-color: #dbf5db;
}

.react-flow__node.module-trigger-overview__node--inactive {
    background-color: lightgray;
}
.module-trigger-overview__inactive-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: orangered;
    font-size: 16px;
}