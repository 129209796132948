
.pagetoggeabletable {
    margin: 1px 0;
}
.pagetoggeabletable .header-box {
    background-color: #7b8898;
    color: #fff;
    padding: 0.5rem 0.75rem;
    font-weight: 600;
    display: flex;
    justify-content: flex-end
}
.pagetoggeabletable .header-box .active-toggle {
    display: inline-block;
    margin-right: 20px;
}
.pagetoggeabletable .header-box .active-toggle .toggle p {
    margin-bottom: 0;
}
    .pagetoggeabletable .header-box .user-toggle p span {
        display: none;
    }
.pagetoggeabletable .header-box .header-content {
    flex: 1;
}
.pagetoggeabletable .header-box .user-toggle span {
    display: flex;
    align-items: center;
}
.pagetoggeabletable .header-box .user-toggle span p {
    margin-bottom: 0;
}
.pagetoggeabletable .header-box .user-toggle span svg {
    margin-left: 10px;
}
.pagetoggeabletable .pagination-box {
    padding: 20px 0;
}
.pagetoggeabletable .active-toggle .toggle-switch-inner:before {
    background-color: #23807d;
}

@media (min-width: 900px) {
    .pagetoggeabletable .header-box .active-toggle {
        margin-right: 40px;
    }
    .pagetoggeabletable .header-box .user-toggle p span {
        display: inline-block;
    }
}