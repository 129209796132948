

/*General*/

.subfooter img {
    width: 100px;
    margin-left: 5px;
}
.subfooter p {
    margin-bottom: 0;
    margin-right: 5px;
}
.subfooter {
    display: flex;
}

/*In menu*/
.nav.subfooter {
    padding: 0.5rem 0;
}
.nav.subfooter a {
    padding: 0;
}
.nav.subfooter a:hover {
    background: transparent;
}
@media (min-width: 992px) {
    .nav.subfooter {
        padding: 15px 25px;
    }
}


