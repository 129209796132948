
.nav-left {
    width: 100%;
    padding: 0;
}
.col-lg-9 {
    padding: 0;
}
.container {
    padding: 0;
}
.layout {
    margin: 0;
}
.children {
    width: 100%;
    min-height: 100vh;
    padding: 0;
}

@media (min-width: 992px) {
    .children {
        margin-left: 300px;
        width: calc(100% - 300px);
        display: flex;
        flex-direction: column;
    }
    .nav-left {
        background-color: #0e303b;
        height: 100%;
        position: fixed;
        z-index: 1;
        left: 0;
        overflow-x: hidden;
        max-width: 300px;
    }
}





