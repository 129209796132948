.gridList {
    display: grid;
    grid-gap: 0;
}

.gridList > .gridListHeader {
    background-color: #7B8898;
    color: #fff;
    padding: 0.5rem 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-bottom: 0;
}

.gridList > .gridListItem {
    column-gap: 10px;
    padding: 0.5rem 0.75rem;
    line-height: 1.5rem;
    border-bottom: 1px solid #ddd;
}

.gridList > .gridListCenterContent {
    display: grid;
    justify-items: center;
    align-items: center;
}

@media (max-width: 600px) {
    .gridList > .gridListItem, .gridList > .gridListHeader {
        padding: 0.5rem 0.2rem;
    }       
}