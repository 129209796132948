.apptoken-configuration {
    border-left: 1px solid var(--bs-border-color-translucent)
}

.apptoken-configuration__status {
    margin-bottom: 0.5rem;
}

.apptoken-configuration__status-icon,
.apptoken-configuration__status-icon--ok,
.apptoken-configuration__status-icon--warning {
    margin-right: 0.5rem;
}

.apptoken-configuration__status-icon--warning {
    color: orange;
}

.apptoken-configuration__status-icon--ok {
    color: green;
}

.apptoken-configuration__button-container {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}