.orderable-list__remove {
    cursor: pointer;
    color: #0e303b;
}

.orderable-list__remove:hover {
    color: #23807d;
}


.orderable-list {
    display: flex;
    padding: 1rem 0;
    overflow-x: auto;
    white-space: nowrap;
}

.orderable-list__item:not(:first-child) {
    margin-left: 1rem;
}

.orderable-list__item {
    border: 1px solid #ccc;
    padding: 0.5rem;
    background-color: white;
    cursor: grab;
}

.orderable-list__item.react-draggable-dragging {
    z-index: 1;
    cursor: grabbing;
}