.login {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 66vh;
}

.login__logo-container {
    margin-bottom: 1rem;
}

.login__logo {
    height: 70px;
    margin-right: 0.5rem;
}

.login__logo-text {
    font-size: 1.5rem;
    vertical-align: middle;
    font-weight: bold;
    display: inline;
}

.login__provider--google {
    position: relative;
    display: inline-block;
}

.login__provider--microsoft {
    border: 1px solid #ccc;
    background: white;
    padding: 0.5rem 0 0.5rem 3rem;
    margin-bottom: 0.5rem;
    position: relative;
    width: 210px;
    cursor: pointer;
    display: inline-block;
    -webkit-transition: 300ms color;
    -o-transition: 300ms color;
    transition: 300ms color;
    font-size: 16px;
}

.login__provider-icon {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 1rem;
    border-right: 1px solid #ccc;
    height: 100%;
    max-width: 1rem !important;
    font-size: 17px;
    transition: 300ms background-color;
}

.login__provider--google > .login__provider-icon {
    color: #D62D20;
}

.login__provider--google:hover {
    color: #D62D20;
}

.login__provider--google:hover > .login__provider-icon {
    background-color: #fceae9;
}

.login__provider--microsoft > .login__provider-icon {
    color: #04A5F0;
}

.login__provider--microsoft:hover {
    color: #04A5F0;
}

.login__provider--microsoft:hover > .login__provider-icon {
    background-color: #e6f7ff;
}