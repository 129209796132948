

/*Grid-table*/

.grid-table .--header {
    display: none;
}
.grid-table .--row {
    padding: 0.5rem 0;
    border-bottom: 1px solid #ddd;
}
.grid-table .--row > * {
    padding: 0.4rem 0.75rem;
    line-height: 1;
}
.grid-table .--row {
    display: grid;
}
.grid-table .--row .mb-3 {
    margin-bottom: 0 !important;
}
.grid-table .--row {
    align-items: center;
}
.grid-table .--row:nth-child(odd) {
    background: #f5f5f5;
}
.grid-table .--header {
    background-color: #7b8898;
    color: #fff;
    font-weight: 600;
}
.grid-table .sm-header {
    background-color: #7b8898;
    color: #fff;
    font-weight: 600;
    padding: 0.5rem 0.75rem;
}
.grid-table .red-border {
    border: 1.5px solid red;
}
@media (min-width: 1400px) {
    .grid-table .sm-header {
        display: none;
    }
    .grid-table .--header {
        display: grid;
    }
    .grid-table .--header > * {
        padding: 0.5rem 0.75rem;
    }
    .grid-table .--row {
        padding: 0;
    }
        .grid-table .--row:nth-child(odd) {
            background: none;
        }
    .grid-table .--row:hover {
        background-color: #eaeaea;
    }
}


/*SchoolUnits*/

.dnp-schoolUnits .--row {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
        "displayName status link"
        "select select select";
}

.dnp-schoolUnits.displayName {
    grid-area: displayName;
}
.dnp-schoolUnits svg {
    grid-area: status;
}
.dnp-schoolUnits a {
    grid-area: link;
}
.dnp-schoolUnits .mb-3 {
    grid-area: select;
}

@media (min-width: 1200px) {
    .dnp-schoolUnits .--header, .dnp-schoolUnits .--row {
        grid-template-columns: 1.5fr 0.5fr 1.5fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "displayName status select link"
    }
}




/*Activities*/

@media (max-width: 1400px) {
    .sm-right {
        text-align: right;
    }

    .grid-table .--row > *:before {
        content: attr(data-label);
        float: left;
        padding-right: 10px;
        font-weight: 600;
    }
}

@media (min-width: 1400px) {
    /*Columns-5*/
    .columns-5 .--header, .columns-5 .--row, .columns-5 .general-header {
        grid-template-columns: repeat(4, 1fr) 15rem;
    }
        .columns-5 .general-header > span:last-child {
            grid-column-start: 5;
        }

    /*Columns-6*/
    .columns-6 .--header, .columns-6 .--row, .columns-6 .general-header {
        grid-template-columns: repeat(5, 1fr) 15rem;
    }
        .columns-6 .general-header > span:last-child {
            grid-column-start: 5;
        }

    /*Columns-7*/
    .columns-7 .--header, .columns-7 .--row, .columns-7 .general-header {
        grid-template-columns: repeat(6, 1fr) 15rem;
    }
        .columns-7 .general-header > span:first-child {
            grid-column-start: 1;
            grid-column-end: 7;
        }    
        .columns-7 .general-header > span:last-child {
            grid-column-start: 7;
            grid-column-end: 7;
        }

    /*Columns-9*/
    .columns-9 .--header, .columns-9 .--row, .columns-9 .general-header {
        grid-template-columns: repeat(6, 1fr) 15rem 4rem 4rem;
    }
    
    .columns-9 .--row.hightlight-edit {
        grid-template-columns: repeat(6, 1fr) 19rem 4rem;
    }
    .columns-9 .--row.hightlight-edit > span[data-label=Status] {
        display: none;
    }

        .columns-9 .general-header > span:first-child {
            grid-column-start: 1;
            grid-column-end: 7;
        }
        .columns-9 .general-header > span:last-child {
            grid-column-start: 7;
            grid-column-end: 9;
        }
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.onelinesearch {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    margin-bottom: 1.5rem;
    align-items: flex-end;
}
.onelinesearch > span > span:first-child {
    display: block;
}
.onelinesearch .mb-3 {
    margin-bottom: 0 !important;
}

@media (min-width: 700px) {
    .onelinesearch {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 1400px) {
    .onelinesearch {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (min-width: 1700px) {
    .onelinesearch {
        grid-template-columns: repeat(6, 1fr) auto;
    }
}

/*Hightlight unmapped activities and updated activities*/
.dnp-activities .highlight-unmapped, .dnp-activities .highlight-updated {
    background: rgba(176,196,222,0.4) !important;
    border: 1.5px solid #b0c4de;
}
.dnp-activities .highlight-unmapped > *, 
.dnp-activities .highlight-removed > *, 
.dnp-activities .highlight-updated > * {
    padding: calc(0.4rem - 1px) 0.75rem;
}

.dnp-activities .highlight-removed {
    background: rgba(255,127,80,0.3) !important;
    border: 1.5px solid #ff7f50;
}

.dnp-activities .hightlight-edit {
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
    border-radius: 5px;
    align-items: center;
}

@media(min-width: 1199px) {
    .dnp-schoolUnits_btn-box {
        display: flex;
        justify-content: flex-end;
    }
}
.dnp-schoolUnits_btn-box .btn {
    padding: 0 0 0 0.5rem;
}
.dnp-schoolUnits_btn-box .btn:disabled {
    border: none;
}


.dnp-filter-bottom .save-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.dnp-filter-bottom .save-box > .btn {
    margin-left: 2rem;
}

.dnp-container {
    padding: 1.5em 1.5em 6.5em 1.5em;
}
@media (min-width: 577px) {
    .dnp-container {
        padding-bottom: 4em;
    }
}
@media (min-width: 992px) {
    .dnp-container {
        padding: 5em 2.5em 4em 2.5em;
    }
}