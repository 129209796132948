
.trigger_configuration label {
    display: block;
}
.add_trigger, .trigger_module {
    margin-top: 2rem;
}
.trigger_module .btn {
    display: block;
    margin-top: 1rem;
}

@media (max-width:600px) {
    .trigger_configuration table tbody tr {
        display: inline-flex;
        width: 100%;
        justify-content: space-between;
    }
}
