
.link {
    display: block;
    font-style: italic;
    color: #007bff;
    font-weight: normal;
    background-color: transparent;
    font-size: 14px;
}
.head .right {
    width: 50%;
    padding: 0;
}

.link:hover {
    cursor: pointer;
}
.flex {
    display: flex;
}

.flex.head {
    font-weight: 600;
    border-bottom: 1px solid grey;
}
    .flex.head > span {
        padding: 0 0.75em 0.5em;
    }
.flex.body {
    border-bottom: 1px solid #dee2e6;
}
    .flex.body > span {
        padding: 0.5em 0.75em;
    }
    .flex.body:nth-child(even) {
        background-color: #f5f5f5;
    }

.btn.start-request {
    margin-right: 1em;
}


.bold {
    font-weight: 600;
}
