.flowsync-copy-icon {
    color: #23807d;
    margin-left: 0.5rem;
    cursor: pointer;
}

    .flowsync-copy-icon:hover {
        color: #1F706D;
    }

.flowsync-copy-icon-disabled {
    color: #7B8898;
    margin-left: 0.5rem;
    cursor: not-allowed;
}

.flowsync-copy-message {
    border-radius: 2px;
    border: 1px solid gray;
    background-color: white;
    z-index: 10 !important;
    display: inline-block;
    position: absolute;
    font-weight: normal;
    font-size: 0.8rem;
    padding: 0 2px;
}
