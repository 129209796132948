.top {
    background-color: #0e303b;
    color: #fff;
    display: flex;
    padding: 0.5rem 0;
    
}
.top > div {
    padding: 0 1rem;
}
.top .user {
    position: absolute;
    right: 0;
    background-color: #0e303b;
}
.top .user .flex {
    justify-content: flex-end;
}
.top .icon_box {
    width: 40px;
    text-align: right;
    cursor: pointer;
}
.top .user-info {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.top .log_out {
    cursor: pointer;
    padding: 1rem 0;
}

@media (min-width: 992px) {
    .top {
        position: fixed;
        width: calc(100% - 300px);
        z-index: 1000;
    }
    .top > div {
        padding: 0;
    }
    .top .user {
        padding-right: 2rem;
        padding-left: 2rem;
    }
}

.language_selector {
    width: 7rem;
    height: 1.6rem;
    padding: 0 10px;
    margin-right: 20px;
    background-color: #556271;
    color: #FFF;
    border: none;
    background-image: url('data:image/svg+xml,\
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">\
        <g stroke-linecap="round" stroke-width="2" stroke="rgb(255, 255, 255)" fill="none">\
            <path d="m2 5 6 6 6-6"/>\
        </g>\
    </svg>')
}
