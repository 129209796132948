
.module-presentation-modal-body {
    background-color: #eaeaea;
    display: flex;
    flex-wrap: wrap;
}
.module-presentation-card {
    padding: 2rem;
    margin: 1rem;
    display: flex;
}
    @media (min-width: 992px) {
        .module-presentation-card {
            width: calc((100% - 4rem) / 2);
        }
    }
    @media (min-width: 1200px) {
        .module-presentation-card {
            width: calc((100% - 6rem) / 3);
        }
    }
    @media (min-width: 1900px) {
        .modal-xl {
            max-width: 1500px;
        }
        .module-presentation-card {
            width: calc((100% - 8rem) / 4);
        }
    }

    .module-presentation-card .btn {
        margin-top: auto;
    }
    .module-presentation-card .btn:disabled:before {
        position: absolute;
        left: 50%;
        padding: 0 5px;
        color: #000;
        background: #fff;
        box-shadow: 2px 2px 2px;
        font-size: 14px;
        transition: opacity 0.5s ease-out;
        opacity: 0;
    }
    .module-presentation-card .not-admin.btn:disabled:before {
        content: 'Endast administratörer kan skapa en ny modul'
    }
    .module-presentation-card .no-license.btn:disabled:before {
        content: 'Licens saknas för att kunna skapa den här modulen';
    }

    .module-presentation-card .btn:disabled:hover:before {
        opacity: 1;
    }

    .module-presentation-card .tags {
        display: flex;
        margin-top: 10px;
    }

        .module-presentation-card .tags .card-subtitle {
            display: inline;
            padding: 0.1rem 0.75rem;
            font-size: 14px;
            border-radius: 5px;
            margin-right: 10px;
        }

        .module-presentation-card .tags .Export {
            background-color: rgba(85,107,47,0.15);
            border: 1px solid rgba(85,107,47,0.5);
        }

        .module-presentation-card .tags .Import {
            background-color: rgba(28,73,102,0.15);
            border: 1px solid rgba(28,73,102,0.5);
        }

        .module-presentation-card .tags .not-released {
            background-color: rgba(105,0,0,0.15);
            border: 1px solid rgba(105,0,0,0.5);
        }

.module-presentation-modal-body .module-toggle {
    margin: 0 1rem;
    width: 100%;
}

.module-presentation-modal-body .module-toggle .btn {
    margin-right: 1rem;
    background-color: transparent;
    border: 2px solid #16504e;
    color: #16504e;
}
.module-presentation-modal-body .module-toggle .btn:last-child {
    margin: 0;
}

    .module-presentation-modal-body .module-toggle .btn-secondary:not(:disabled):not(.disabled).active {
        background-color: #16504E;
        color: #fff;
    }

.module-presentation-card .logo {
    object-fit: contain;
}
.module-presentation-card .card-title {
    margin-top: 1rem;
}
.module-presentation-card .vendor {
    height: 80px;
    max-width: 150px;
    margin: 0 auto;
}

.module-presentation-card .vendorName {
    font-size: 2rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.module-presentation-card .description {
    margin-top: 1rem;
}
