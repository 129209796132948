
.home_bg {
    background: #eaeaea;
    min-height: 100vh;
}

.dashboard_module {
    color: #000;
    margin: 2rem 0;
    position: relative;
    background: #fff;
}

.dashboard_top {
    min-width: 2.2rem;
    background-color: #0e303b;
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding: 1rem;
}
    .dashboard_top h6 {
        margin: 0;
    }

.icon_active {
    font-size: 1.5rem;
}

.dashboard_side .icon {
    margin: 0.5rem 0;
}

.icon_text {
    font-size: 0.7rem;
    font-weight: 100;
    margin-right: 2rem;
    margin-left: 0.5rem;
}

.dashboard_content {
    padding: 0.5rem 1rem 1rem;
}

.dashboard_module p {
    margin: 0;
    font-size: .9em;
    font-weight: 100;
}

.bg_green {
    background-color: green;
}

.green {
    color: green;
}

.bg_yellow {
    background-color: yellow;
}

.yellow {
    color: yellow;
}

.bg_red {
    background-color: red;
}

.red {
    color: red;
}

.blue {
    color: #007bff;
}

.column {
    width: 100%;
    padding: 0 15px;
}
@media (min-width: 1200px) {
    .w30 {
        width: 30%;
    }
    .w40 {
        width: 40%;
    }
}


/******************* Buttons *******************/

.btn-primary {
    border-color: #23807d;
    background-color: #23807d;
}
    .btn-primary:hover, .btn-primary:active, .btn:first-child:active {
        background-color: #16504E;
        border-color: #16504E;
    }
    .btn-primary:disabled {
        background-color: #6c757d;
        border-color: #6c757d;
        cursor: not-allowed;
    }

.p-xs {
    font-size: 14px;
}
.p-link {
    color: blue;
    cursor: pointer;
}
.ml-2 {
    margin-left: 2rem;
}
