

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}



.dark {
    background-color: #0e303b;
    padding: 1rem;
    color: #fff;
}
.fa-bars {
    color: #FFF;
}
.dark .brand {
    color: #FFF;
    font-size: 22px;
    font-weight: 400;
}
.dark .nav-item a {
    color: #FFF;
    transition: background 0.2s;
}
.navbar-nav .nav-link.active {
    color: #fff;
}
.nav_icon {
    min-width: 20px;
    margin-right: 20px;
}
.logo {
    height: 50px;
    margin-right: 20px; 
}
.navbar-collapse {
    padding-top: 1rem;
}
.navbar {
    padding: 10px 20px;
}
.navbar .container-fluid {
    padding: 0;
    height: 100%;
}

.open {
    position: absolute;
    right: 25px;
}
.navbar-nav .nav-item .submenu {
    padding-left: 60px;
}

    /* On large screens, convert the nav menu to a vertical sidebar */
@media (min-width: 992px) {
    
    .navbar-collapse {
        padding-top: 0;
    }
    .navbar .collapse:not(.show) {
        display: grid;
        min-height: calc(100% - 120px);
    }
    .dark button {
        display: none;
    }
    .dark {
        padding: 0;
    }
    .dark .nav-item a {
        padding: 15px 25px;
    }
    .dark .navbar-nav {
        min-height: 100%;
    }
    .dark .nav-item:last-child{
        margin-top: auto;
    }
    .dark .nav-item a:hover {
        background: #556271;
    }
    .active {
        background: #7b8898;
    }
    .logo {
        height: 70px;
        margin: 20px;
    }
    .navbar {
        height: 100vh;
        align-content: flex-start;
    }
    .logout {
        position: absolute;
        bottom: 20px;
    }


    
}

